import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import SEO from '../components/seo';
import LogoImage from '../images/logo-vertical.svg';
import NotFoundErrorDesktopImage from '../images/error-404-desktop.svg';
import NotFoundErrorMobileImage from '../images/error-404-mobile.svg';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint.xs || breakpoint.sm;

  return (
    <>
      <SEO title={t('page_not_found')} />

      <Container className="no-margin-top">
        <Row>
          <Col
            md={6}
            className="d-flex flex-column align-items-center mx-auto mt-4 mb-5"
          >
            <LogoImage className="img-success" />

            {isMobile && <NotFoundErrorMobileImage className="mb-4 mb-md-0" />}

            {!isMobile && <NotFoundErrorDesktopImage className="error-image" />}

            <h1 className="text-center mb-3 reduced-line-height">
              {t('oops')} <br className="d-md-none" />
              <span className="small-sm-down">{t('page_not_found_title')}</span>
            </h1>
            <h2 className="lead text-center mb-4">
              {t('page_not_found_message')}
            </h2>

            <Link to="/" className="btn btn-primary">
              {t('home')}
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NotFoundPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
